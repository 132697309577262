<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
export default {
  name: "HomeCitataBlock",
  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    getBlock() {
      let params = "&auth=" + this.user.username + ":" + this.user.auth_key;

      console.log("get-quotes", this.apiUrl + "api/quotes/get-quotes" + params);
      axios
        .get(this.apiUrl + "api/quotes/get-quotes" + params)
        .then((response) => {
          console.log("citatasResponse", response);
          this.citats = response.data;
          console.log("citats", this.citats);
        });
    },
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper2 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper2,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      citats: {},
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  mounted() {
    this.getBlock();
  },
};
</script>

<template>
  <div class="home-citata-block sector168">
    <div class="container">
      <div class="home-citata__content">
        <div class="home-citata__head">
          <h2 class="head-h2">ЦИТАТЫ</h2>
          <div class="arrows">
            <a class="prev" :class="{ 'grey-icon': isFirstSlide }">
              <svg
                width="56"
                height="21"
                viewBox="0 0 56 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.15147 11.2314H54.5669C55.2022 11.2314 55.7156 10.7181 55.7156 10.0827C55.7156 9.44731 55.2022 8.93397 54.5669 8.93397H3.92635L10.8976 1.96269C11.3463 1.51397 11.3463 0.785256 10.8976 0.336538C10.4489 -0.112179 9.72019 -0.112179 9.27147 0.336538L0.336601 9.27141C0.00634384 9.60167 -0.09058 10.0935 0.0889091 10.5242C0.268394 10.9514 0.688393 11.2314 1.15147 11.2314Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M10.097 20.1769C10.3914 20.1769 10.6858 20.0656 10.9083 19.8395C11.3571 19.3908 11.3571 18.6621 10.9083 18.2133L1.96269 9.26769C1.51397 8.81897 0.785257 8.81897 0.336539 9.26769C-0.112179 9.71641 -0.112179 10.4451 0.336539 10.8938L9.28218 19.8395C9.50833 20.0656 9.80269 20.1769 10.097 20.1769Z"
                  fill="#D9D9D9"
                />
              </svg>
            </a>
            <a class="next" :class="{ 'grey-icon': isLastSlide }">
              <!-- Добавлено применение класса grey-icon -->
              <svg
                width="56"
                height="21"
                viewBox="0 0 56 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M54.5641 11.2314H1.14872C0.513333 11.2314 0 10.7181 0 10.0827C0 9.44731 0.513333 8.93397 1.14872 8.93397H51.7892L44.818 1.96269C44.3692 1.51397 44.3692 0.785256 44.818 0.336538C45.2667 -0.112179 45.9954 -0.112179 46.4441 0.336538L55.379 9.27141C55.7092 9.60167 55.8062 10.0935 55.6267 10.5242C55.4472 10.9514 55.0272 11.2314 54.5641 11.2314Z"
                  fill="#181818"
                />
                <path
                  d="M45.6185 20.1769C45.3242 20.1769 45.0298 20.0656 44.8072 19.8395C44.3585 19.3908 44.3585 18.6621 44.8072 18.2133L53.7529 9.26769C54.2016 8.81897 54.9303 8.81897 55.379 9.26769C55.8278 9.71641 55.8278 10.4451 55.379 10.8938L46.4334 19.8395C46.2072 20.0656 45.9129 20.1769 45.6185 20.1769Z"
                  fill="#181818"
                />
              </svg>
            </a>
          </div>
        </div>
        <swiper
          :slides-per-view="2"
          :space-between="35"
          
          :modules="modules"
          :navigation="{enabled: true, prevEl: '.prev', nextEl: '.next'}"
          :breakpoints="{
            0: {
              slidesPerView: 1,
            },
            550: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
          }"
          @swiper="onSwiper2"
          class="mySwiper"
        >
          <swiper-slide v-for="item in citats" :key="item.id">
            <div class="home-citata__item">
              <div class="home-citata-item__content">
                <div class="home-citata__text">
                  {{ item.text }}
                </div>
                <div class="home-citata__author">
                  {{ item.author }}
                </div>
              </div>
              <img
                :src="apiDomain + '/web/uploads' + item.pic"
                alt=""
                class="home-citata__img"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-citata__content {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.home-citata__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swiper-slide {
  width: max-content;
  cursor: grabbing;
  height: 100%;
}
.swiper {
  width: 100%;
}
.home-citata__item {
  display: flex;
  align-items: center;
  gap: 35px;
  border: 1px solid #d9d9d9;
  padding: 34px;
}
.home-citata__item img {
  width: 187px;
  height: 187px;
}
.home-citata-item__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 619px;
}
.home-citata__text {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.home-citata__author {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.grey-icon path {
  fill: grey; /* Применение серого цвета к иконкам */
}
@media screen and (max-width: 550px) {
  .home-citata__item {
    flex-direction: column;
    gap: 18px;
    padding: 17px;
  }
  .home-citata__text, .home-citata__author {
    font-size: 16px;
  }
}
</style>