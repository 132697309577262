<script>
export default {
  name: "HomeAdvantagesBlock",
};
</script>

<template>
  <div class="home-advantages-block">
    <div class="container sector">
      <div class="home-advantages__content">
        <div class="home-advantages__item">
          <img src="../../assets/img/1.svg" alt="" />

          <div class="home-advantages__text">
            <h3>
              Акции для постоянных <br />
              клиентов в дни праздников
            </h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <img src="../../assets/img/2.svg" alt="" />

          <div class="home-advantages__text">
            <h3>Помощь в выборе часов</h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <img src="../../assets/img/3.svg" alt="" />

          <div class="home-advantages__text">
            <h3>
              Бесплатная доставка наручных часов <br />
              по всей России
            </h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <img src="../../assets/img/4.svg" alt="" />

          <div class="home-advantages__text">
            <h3>
              Хорошее место 5,0 <br />
              Выбор пользователей Яндекса
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-advantages__content {
  display: flex;
  justify-content: center;
  gap: 49px;
}
.home-advantages__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  width: 386px;
}
.sector {
  margin-bottom: 80px;
}
.home-advantages__text h3 {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
@media screen and (max-width: 1440px) {
  .home-advantages__item{
    width: 208px;
  }
}
@media screen and (max-width: 1028px) {
  .home-advantages__content{
    flex-wrap: wrap;
    gap: 96px;
  }
  .home-advantages__text h3{
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .home-advantages__text h3{
    font-size: 16px;
  }
  .home-advantages__item{
    gap: 10px;
    width: 173px;
    max-width: calc(50% - 27px);
  }
  .home-advantages__content{
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>