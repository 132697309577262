<script>
import MainBtn from "../ui/main-btn.vue";
export default {
  name: "HomeMainBlock",
  components: {
    MainBtn
  }
};
</script>

<template>  
    <div class="home-main-block">
        <div class="container h100">
            <div class="home-main__content">
                <div class="home-main__text">
                    стоит посетить <br>
                    стоит примерить <br>
                    стоит купить

                    <main-btn style="margin-top: 30px" :link="'/shop'" :msg="'Купить'"></main-btn>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.home-main-block{
    background-image: url(../../assets/img/main.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    height: calc(100vh - 89px);
}
.home-main__content{
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 156px;
}
.home-main__text{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 58.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
}
@media screen and (max-width: 1028px) {
  .home-main__content {
    padding-left: 41px;
  }
  .home-main__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 48.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .home-main-block {
    background-position: center;
  }
}
@media screen and (max-width: 550px) {
  .home-main__content {
    padding-left: 0px;
  }
  .home-main__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 32.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .home-main-block {
    background-position: center;
  }
}
</style>