<script>
class Clock7 {
  constructor(el) {
    this.el = document.querySelector(el);
    this.init();
  }

  init() {
    this.timeUpdate();
  }

  get timeAsObject() {
    const date = new Date();
    let h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();

    return { h, m, s };
  }

  get timeAsString() {
    const [h, m, s] = this.timeDigitsGrouped; // Убираем метку AM/PM
    return `${h}:${m}:${s}`;
  }

  get timeDigitsGrouped() {
    let { h, m, s } = this.timeAsObject;
    // Убираем 12-часовой формат и оставляем 24-часовой
    // prepend 0 to the minute and second if single digits
    if (h < 10) h = `0${h}`;
    if (m < 10) m = `0${m}`;
    if (s < 10) s = `0${s}`;

    return [h, m, s]; // Убираем метку AM/PM
  }

  timeUpdate() {
    // update the accessible timestamp in the `aria-label`
    this.el?.setAttribute("aria-label", this.timeAsString);
    // move the hands
    const time = this.timeAsObject;
    const minFraction = time.s / 60;
    const hrFraction = (time.m + minFraction) / 60;
    const twentyFourHrFraction = (time.h + hrFraction) / 24; // Изменение на 24-часовой формат

    this.el?.style.setProperty("--minAngle", `${360 * hrFraction}deg`);
    this.el?.style.setProperty("--hrAngle", `${360 * twentyFourHrFraction}deg`);
    // update the digits
    Array.from(document.querySelectorAll(`[data-unit]`)).forEach((unit, i) => {
      unit.innerText = this.timeDigitsGrouped[i];
    });
    // loop
    clearTimeout(this.timeUpdateLoop);
    this.timeUpdateLoop = setTimeout(this.timeUpdate.bind(this), 1e3);
  }
}

import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import mainBtn from "../ui/main-btn.vue";
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "CartPage",
  components: {
    HeaderApp,
    FooterApp,
    BreadCrumbs,
    mainBtn,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      cart: null,
      carId: null,
      products: [],
      totalPrice: 0,
      quantityProducts: 0,
    };
  },
  methods: {
    noCart() {
      new Clock7(".clock");
    },

    quantityValueMinus(id) {
      this.quantityProducts = 0;
      this.totalPrice = 0;
      for (let p of this.products) {
        if (p.product.id == id) {
          if (p.product.quantity > 1) {
            let quantity = parseInt(p.product.quantity);
            quantity -= 1;
            p.product.quantity = quantity;
          }
        }
        this.totalPrice +=
          parseInt(p.product.price) * parseInt(p.product.quantity);
        this.quantityProducts += parseInt(p.product.quantity);
      }
    },
    quantityValuePlus(id) {
      this.quantityProducts = 0;
      this.totalPrice = 0;
      for (let p of this.products) {
        console.log(p);
        if (p.product.id == id) {
          let quantity = parseInt(p.product.quantity);
          quantity += 1;
          p.product.quantity = quantity;
        }
        this.totalPrice +=
          parseInt(p.product.price) * parseInt(p.product.quantity);
        this.quantityProducts += parseInt(p.product.quantity);
      }
    },
    inputsCheck() {
      let inputs = document.querySelectorAll("input");

      inputs.forEach((input) => {
        input.addEventListener("input", () => {
          if (input.classList.contains("error")) {
            input.classList.remove("error");
          }
        });
      });
    },
    getCart() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&cart_id=${this.carId}`;

      axios
        .get(this.apiUrl + "api/cart/get-cart-by-id" + params)
        .then((response) => {
          this.cart = response.data;
          this.productsPush(this.cart.cartProduct);
          console.log("CART", this.cart);
        })
        .catch((error) => {
          console.log(error);
          console.log("CART", this.cart);
          if (this.cart.status == false) {
            this.noCart();
          }
        });
    },
    sendOrder() {
      let firstName = document.getElementById("firstName");
      let lastName = document.getElementById("lastName");
      let email = document.getElementById("email");
      let phone = document.getElementById("phone");
      let city = document.getElementById("city");
      let street = document.getElementById("street");
      let house = document.getElementById("house");
      let apartment = document.getElementById("apartment");

      // Сброс предыдущих ошибок
      const fields = [
        firstName,
        lastName,
        email,
        phone,
        city,
        street,
        house,
        apartment,
      ];
      fields.forEach((field) => field.classList.remove("error"));

      if (this.quantityProducts == 0 || this.products.length == 0) {
        alert("Корзина пуста");
        return false;
      }

      let missingFields = [];

      // Проверка полей на пустоту
      if (firstName.value.trim() === "") missingFields.push(firstName);
      if (lastName.value.trim() === "") missingFields.push(lastName);
      if (phone.value.trim() === "") missingFields.push(phone);

      if (missingFields.length > 0) {
        alert("Заполните все поля");

        // Добавление класса 'error' пустым полям
        missingFields.forEach((field) => field.classList.add("error"));

        return false;
      }

      let order = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        city: city.value,
        street: street.value,
        house: house.value,
        apartment: apartment.value,
        products: this.products,
        totalPrice: this.totalPrice,
        quantityProducts: this.quantityProducts,
      };

      axios
        .post(this.apiUrl + "api/order/create-order", order)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    productsPush(products) {
      for (let p of products) {
        console.log(p.product_id);
        let brand = null;
        let params2 = `&auth=${this.user.username}:${this.user.auth_key}&id=${p.product_id}`;
        axios
          .get(this.apiUrl + "api/product/get-product-by-id" + params2)
          .then((response) => {
            for (let b of response.data.attribute) {
              if (b != null && b.attrGroup && b.attrGroup == "Производитель") {
                brand = b.attr;
              }
            }
            this.totalPrice +=
              parseInt(response.data.price) * parseInt(response.data.quantity);
            this.quantityProducts += parseInt(response.data.quantity);
            let product = {
              product: response.data,
              brand: brand,
            };
            this.products.push(product);
            console.log("Продукты", this.products);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    const cartIdFromCookie = Cookies.get("cartId");
    if (cartIdFromCookie) {
      this.carId = cartIdFromCookie;
    }
    this.getCart();
    this.inputsCheck();
  },
};
</script>

<template>
  <HeaderApp></HeaderApp>

  <BreadCrumbs></BreadCrumbs>

  <div class="container">
    <div class="products-head__content">
      <h1 class="sub-head-h2 black">Корзина</h1>
      <a v-if="cart?.status != false" href="" class="deleteAll"
        >Очистить корзину</a
      >
    </div>

    <div v-if="cart?.status != false" class="cart">
      <div class="cart__head">
        <div class="cart__head-item">Фото</div>
        <div class="cart__head-item">Бренд</div>
        <div class="cart__head-item">Название</div>
        <div class="cart__head-item">Количество</div>
        <div class="cart__head-item">Цена</div>
      </div>
      <div v-if="cart?.status != false" class="cart__content">
        <div class="cart__item" v-for="product in products" :key="product.id">
          <div class="cart__item-img">
            <img
              :src="
                apiDomain +
                '/web/uploads/products/' +
                product.product.productPhoto[0]
              "
              alt=""
            />
          </div>
          <div class="cart__item-brand">{{ product.brand }}</div>
          <div class="cart__item-name">{{ product.product.name }}</div>
          <div class="cart__item-quantity">
            <span
              @click="quantityValueMinus(product.product.id)"
              :data-productId="product.product.id"
              class="minus"
              >-</span
            ><span class="number">{{ product.product.quantity }}</span
            ><span
              @click="quantityValuePlus(product.product.id)"
              :data-productId="product.product.id"
              class="plus"
              >+</span
            >
          </div>
          <div class="cart__item-price">
            <span class="price">{{ product.product.price }}</span>
            ₽
          </div>
          <div class="delete">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M1.21894 15.7805C1.26828 15.8298 1.32687 15.869 1.39136 15.8958C1.45585 15.9225 1.52498 15.9363 1.5948 15.9363C1.66461 15.9363 1.73374 15.9225 1.79823 15.8958C1.86273 15.869 1.92132 15.8298 1.97066 15.7805L8.49972 9.25139L15.0314 15.7805C15.1311 15.8801 15.2663 15.9361 15.4073 15.9361C15.5483 15.9361 15.6835 15.8801 15.7832 15.7805C15.8828 15.6808 15.9388 15.5456 15.9388 15.4046C15.9388 15.2636 15.8828 15.1284 15.7832 15.0287L9.25144 8.49967L15.7805 1.96795C15.8802 1.86827 15.9362 1.73307 15.9362 1.59209C15.9362 1.45112 15.8802 1.31592 15.7805 1.21623C15.6808 1.11655 15.5456 1.06055 15.4046 1.06055C15.2637 1.06055 15.1285 1.11655 15.0288 1.21623L8.49972 7.74795L1.968 1.21889C1.86637 1.13186 1.73564 1.08638 1.60194 1.09154C1.46824 1.09671 1.34141 1.15213 1.24679 1.24675C1.15218 1.34136 1.09675 1.46819 1.09159 1.60189C1.08643 1.73559 1.1319 1.86632 1.21894 1.96795L7.748 8.49967L1.21894 15.0314C1.11999 15.1309 1.06445 15.2656 1.06445 15.4059C1.06445 15.5463 1.11999 15.6809 1.21894 15.7805Z"
                fill="#181818"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cart?.status != false" class="cart__promo">
      <div class="promocode">
        <input type="text" placeholder="Укажите промокод" />
        <mainBtn :className="'green'" :msg="'Применить'"></mainBtn>
      </div>
      <div class="productsCartAll">
        <div class="productsCartAll__head">
          Кол-во товаров:
          <span class="productsCartAll__head-number">{{
            quantityProducts
          }}</span>
        </div>
        <div class="productsCartAll__price">
          Итого:
          <span class="productsCartAll__price-number">{{ totalPrice }} </span> ₽
        </div>
      </div>
    </div>
    <div v-if="cart?.status != false" class="order">
      <h2 class="sub-head-h2 black">Оформление заказа</h2>
      <form action="">
        <div class="form__items">
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">1. Контактные данные</h3>
              <a href="" class="accountLogin"
                >Войти и заполнить автоматически</a
              >
            </div>
            <div class="form__item-content">
              <label for="" class="input-label">
                Имя *
                <input
                  type="text"
                  id="firstName"
                  placeholder="Введите имя"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Фамилия *
                <input
                  type="text"
                  placeholder="Введите фамилию"
                  class="input"
                  id="lastName"
                />
              </label>
              <label for="" class="input-label">
                Телефон *
                <input
                  type="text"
                  placeholder="Введите телефон"
                  class="input"
                  id="phone"
                />
              </label>
              <label for="" class="input-label">
                Email
                <input
                  type="text"
                  id="email"
                  placeholder="Введите e-mail"
                  class="input"
                />
              </label>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">2. Адрес доставки</h3>
            </div>
            <div class="form__item-content">
              <label for="" class="input-label">
                Город
                <input
                  type="text"
                  id="city"
                  placeholder="Введите город"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Улица
                <input
                  type="text"
                  id="street"
                  placeholder="Введите улицу"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Дом
                <input
                  type="text"
                  placeholder="Введите квартиру"
                  class="input"
                  id="house"
                />
              </label>
              <label for="" class="input-label">
                Квартира
                <input
                  type="text"
                  id="apartment"
                  placeholder="Введите дом"
                  class="input"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="form__items step34">
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">3. Способ доставки</h3>
            </div>
            <div class="form__item-content step34">
              <input
                type="radio"
                value="Самовывоз г. Севастополь, ул. Генерала Острякова 260 - 0р."
                class="input-radio"
                id="magaz1"
                name="pickup"
              />
              <label for="magaz1" class="input-labelStep34">
                Самовывоз г. Севастополь, ул. Генерала Острякова 260 - 0р.
              </label>
              <input
                type="radio"
                value="Самовывоз г. Севастополь, ул. Генерала Острякова 260 - 0р."
                class="input-radio"
                id="magaz2"
                name="pickup"
              />
              <label for="magaz2" class="input-labelStep34">
                Самовывоз г. Севастополь, ул. Б. Морская д.52 - 0р.
              </label>
              <input
                type="radio"
                value="Самовывоз г. Севастополь, ул. Генерала Острякова 260 - 0р."
                class="input-radio"
                id="magazFree"
                name="pickup"
              />
              <label for="magazFree" class="input-labelStep34">
                Доставка - 0р.
              </label>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">4. Способ оплаты</h3>
            </div>
            <div class="form__item-content">
              <input
                type="radio"
                value="Оплата при получении"
                class="input-radio"
                id="pay1"
                name="pay"
              />
              <label for="pay1" class="input-labelStep34">
                Оплата при получении
              </label>
              <input
                type="radio"
                value="Оплата частями"
                class="input-radio"
                id="pay2"
                name="pay"
              />
              <label for="pay2" class="input-labelStep34">
                Оплата частями
              </label>
              <input
                type="radio"
                value="Оплата картой"
                class="input-radio"
                id="cardpay"
                name="pay"
              />
              <label for="cardpay" class="input-labelStep34">
                Оплата картой
              </label>
            </div>
          </div>
          <div class="final">
            <div class="final__price">
              Итого, с доставкой: <span class="final__price">47 980 ₽ </span>
            </div>
            <mainBtn
              @click="sendOrder"
              :className="'green'"
              text="Оформить заказ"
            ></mainBtn>
          </div>
        </div>
      </form>
    </div>
    <div v-if="cart?.status == false" class="promo">
      <div class="promo__text">
        Ваша корзина пуста. <br />Пожалуйста, добавьте что-нибудь в нее
      </div>
      <div class="clock" role="img" tabindex="0">
        <div class="clock__layer clock__layer--img"></div>
        <div class="clock__layer clock__layer--shade"></div>
        <div class="clock__layer clock__layer--face">
          <div class="clock__digits">
            <span class="clock__digit-group" data-unit="h">00</span>
            <span>:</span>
            <span class="clock__digit-group" data-unit="m">00</span>
            <small
              class="clock__digit-group clock__digit-group--small"
              data-unit="s"
              >00</small
            >
            
          </div>
          <div class="clock__hand clock__hand--hr"><img src="../../assets/img/str.svg" alt=""></div>
          <div class="clock__hand clock__hand--min"><img src="../../assets/img/str.svg" alt=""></div>
          <div class="clock__ring"> <img src="../../assets/img/elips.svg" alt=""></div>
        </div>
        <div class="clock__layer clock__layer--profile">
          
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>
:root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 40%);
  --fg: hsl(0, 0%, 100%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (32 - 16) * (100vw - 320px) / (2560 - 320));
}
.promo {
  padding: 100px 0;
  width: 100%;
}
.promo__text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
}
.clock {
  --hrAngle: 0;
  --minAngle: 0;
  border-radius: 50%;
  margin: auto;
  outline: transparent;
  position: relative;
  width: 12em;
  height: 12em;
  transform: rotateX(30deg) rotateY(-30deg) rotateZ(30deg);
  transform-style: preserve-3d;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-tap-highlight-color: transparent;
}
.promo:focus-visible .clock,
.promo:hover .clock {
  transform: rotateX(0) rotateY(0) rotateZ(0);
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}
.clock__digits {
  display: flex;
  justify-content: center;
  align-items: end;
  line-height: 1;
  margin-top: 2.25em;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.clock__digit-group {
  margin: 0 0.1em;
  width: 2ch;
}
.clock__digit-group[data-unit="h"] {
  text-align: right;
}
.clock__digit-group--small {
  font-size: 0.75em;
}
.clock__hand,
.clock__layer,
.clock__ring {
  position: absolute;
}
.clock__ring{
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
}
.clock__ring img{
  width: 100%;
}
.clock__hand {
  bottom: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  width: 1em;
  /* mix-blend-mode: difference; */
  perspective: 4.25em;
  transform-origin: 0.5em calc(100% - 0.5em);
}
.clock__hand--hr {
  height: 2.75em;
  transform: rotate(var(--hrAngle)) translateY(-1em);
}
.clock__hand--min {
  height: 3.75em;
  transform: rotate(var(--minAngle)) translateY(-1em);
}
/* .clock__hand:before {
  background: url("../../assets/img/str.svg");
  background-repeat: no-repeat;
  content: "";
  display: block;
  transform: rotateX(-30deg);
  transform-origin: 50% 100%;
} */
.clock__hand img{
  height: 126%;
}
.clock__hand--hr:before {
  border-radius: 0.5em 0.5em 0.5em 0.5em / 0.5em 0.5em 0.75em 0.75em;
}
.clock__hand--min:before {
  border-radius: 0 0 0.5em 0.5em / 0 0 0.75em 0.75em;
}
.clock__layer {
  inset: 0;
}
.clock__layer--face {
  transform: translateZ(3.75em);
}
.clock__layer--img {
  background: url("../../assets/img/clock.svg") 0 0 / 100% 100%;
  transform: translateZ(-3.75em);
}
.clock__layer--profile {
  transform: translateZ(11em);
}
.clock__layer--shade {
  background-color: hsla(var(--hue), 90%, 10%, 0.6);
}
.clock__profile {
  background-color: hsl(var(--hue), 10%, 50%);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 0.25em hsla(var(--hue), 90%, 10%, 0.6);
  display: block;
  margin: 7.75em auto 0 auto;
  width: 2em;
  height: 2em;
}


.cart {
  margin-top: 35px;
}
.cart__head {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 0.2fr;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
}
.cart__head-item {
  text-align: center;
}
.cart__item {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 0.2fr;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.cart__item div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.cart__item-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart__item-quantity span {
  cursor: pointer;
  padding: 8px 19px;
  border-right: 1px solid #d9d9d9;
}
.cart__item-quantity span:last-child {
  border: none;
}
.delete {
  cursor: pointer;
}
.deleteAll {
  color: #a5a5a5;
  text-align: right;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
}
.products-head__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promocode input {
  border: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  padding: 17px 21px;
  width: 350px;
}
.promocode input::placeholder {
  color: #d9d9d9;
}
.promocode {
  display: flex;
}
.cart__promo {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 70px;
}
.productsCartAll {
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: right;
  color: #1f1f1f;
  text-align: right;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 29.078px */
}
.order {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 168px;
}
form {
  display: flex;
  gap: 27px;
  border: 1px solid #d9d9d9;
  padding: 35px;
}

.input:focus {
  outline: none;
}
.input-labelStep34 {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-left: 30px;
  position: relative;
}
.input-labelStep34 {
  position: relative;
  padding-left: 28px; /* Отступ для размещения псевдоэлемента */
  display: inline-block;
  cursor: pointer;
}

.input-labelStep34::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(57, 126, 51, 1);
  border-radius: 50%;
  background: none;
}

.input-radio {
  display: none; /* Скрыть оригинальные радиокнопки */
}

.input-radio:checked + .input-labelStep34::before {
  background: url(../../assets/img/chek.svg) no-repeat center;
  background-size: 100%;
  border: none;
  border: 1px solid transparent;
}
.step34 .form__item-content {
  flex-direction: column;
}
.final {
  display: flex;
  flex-direction: column;
  gap: 19px;

  margin-top: auto;
}
.final__price {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.step34 {
  justify-content: space-between;
}
.cart__item-img {
  max-width: 157px;
}
@media screen and (max-width: 1024px) {
  form {
    flex-direction: column;
    gap: 25px;
  }

  .cart__item-img {
    max-width: 110px;
  }
}
@media screen and (max-width: 650px) {
  .cart__head {
    display: none;
  }
  .cart__item-img {
    max-width: 91px;
  }
  .cart__item {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 9px;
    font-size: 14px;
  }
  .cart__item-img {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .cart__item-name {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .cart__item-brand {
    display: none !important;
  }
  .cart__item-price {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
  }
  .cart__promo {
    flex-direction: column;
  }
  .cart__item div {
    justify-content: flex-start;
  }
  .delete {
    margin-left: auto;
  }
  .promocode input {
    width: 70%;
  }
  .cart__item-quantity span {
    padding: 0 10px;
  }
  .cart__promo {
    align-items: flex-start;
    gap: 20px;
  }
  .productsCartAll {
    text-align: left;
    font-size: 16px;
  }

  form {
    padding: 15px;
  }
  .order {
    gap: 14px;
  }

  .promocode input {
    font-size: 16px;
    padding: 12px;
  }

  .input-labelStep34 {
    font-size: 14px;
  }
  .input-labelStep34::before {
    top: 0;
  }
  .deleteAll {
    font-size: 16px;
  }
  .cart__item:first-of-type {
    border-top: 1px solid #d9d9d9;
  }
  .cart {
    margin-top: 14px;
  }
  .products-head__content {
    margin-top: 27px;
  }
}
</style>