import Main from '@/components/pages/HomePage.vue';
import ProductsPage from '@/components/pages/ProductsPage.vue';
import ProductPage from '@/components/pages/ProductPage.vue';
import ServicePage from '@/components/pages/ServicesPage.vue';
import CartPage from '@/components/pages/CartPage.vue';
import AboutPage from '@/components/pages/AboutPage.vue';
import DeliveryPage from '@/components/pages/DeliveryPage.vue';
import ContactsPage from '@/components/pages/ContactsPage.vue';
import ComparePage from '@/components/pages/ComparePage.vue';
import AccountPage from '@/components/pages/AccountPage.vue';

import { createRouter, createWebHistory } from 'vue-router';



const routes = [
  //Для всех
  {
    path: '/',
    component: Main,
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/shop',
    component: ProductsPage,
  },
  {
    path: '/cart',
    component: CartPage,
  },
  {
    path: '/service',
    component: ServicePage
  },
  {
    path: '/about',
    component: AboutPage
  },
  {
    path: '/delivery',
    component: DeliveryPage
  },
  {
    path: '/contacts',
    component: ContactsPage
  },
  {
    path: '/compare',
    component: ComparePage
  },
  {
    path: '/account',
    component: AccountPage
  },


]

const router = createRouter({
  mode: 'history',
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})


export default router;
