<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import MiniProductVue from "../product/MiniProduct.vue";
import axios from "axios";
import FilteresBlockVue from '../blocks/FilteresBlock.vue';

export default {
  name: "ProductsPage",
  components: {
    HeaderApp,
    FooterApp,
    BreadCrumbs,
    MiniProductVue,
    FilteresBlockVue
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      products: [],
      baseProducts: [],
      filteres: [],
      totalPages: 1,
      currentPage: 1,
      banner: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      activeFilter: null,
      priceRange: {
        min: 0,
        max: 100000,
      },
      selectedAttributes: {},
      filterPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      const limit = this.currentPage === 1 ? 14 : 16;
      const offset =
        this.currentPage === 1 ? 0 : 1 + (this.currentPage - 2) * 4;

      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      if (this.categoryId != 0) {
        params += `&group_id=${this.categoryId}`;
        axios
          .get(`${this.apiUrl}api/product/get-product-by-category${params}`)
          .then((response) => {
            this.products = response.data.products;
            this.baseProducts = response.data.products;
            this.filteredProducts = this.products;
          });
      } else {
        params += `&limit=${limit}&offset=${offset}`;
        axios
          .get(`${this.apiUrl}api/product/get-product${params}`)
          .then((response) => {
            this.products = response.data.products;
            this.baseProducts = response.data.products;
            this.totalPages = Math.ceil(response.data.total / 2);
            this.filteredProducts = this.products;
            this.filteres = response.data.allAttributes;
            console.log(response.data);
          });
      }
    },
    updateProducts(ids) {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      let paramsPost = {};
      if (ids.lenght > 0) {
        paramsPost.attribute_id = ids;
      }
      axios
        .post(`${this.apiUrl}api/product/get-product-filter` + params, paramsPost)
        .then((response) => {
          console.log('filterproducts', response.data);
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getProducts();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getProducts();
      }
    },
    goToPage(page) {
      if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.getProducts();
      }
    },
    
  },
};
</script>


<template >
  <HeaderApp></HeaderApp>
  <BreadCrumbs></BreadCrumbs>
  <div class="products-head" >
    <div class="container">
      <div class="products-head__content">
        <h1 class="sub-head-h2 black">Мужские часы</h1>
      </div>
      <FilteresBlockVue @update-filters="(ids)=>{updateProducts(ids)}" :products="products"></FilteresBlockVue>
      <div class="products">
        <div :class="['banner', { hide: currentPage > 1 }]">
          <img src="../../assets/img/banner.png" />
        </div>
        <MiniProductVue
          v-for="item in this.products"
          :key="item.id"
          :product="item"
        ></MiniProductVue>
      </div>
      <div class="pagination">
        <button
          @click="prevPage"
          :disabled="currentPage === 1"
          :class="['btn', { active: currentPage > 1 }]"
        >
          🡠 Предыдущая
        </button>
        <button
          v-for="page in totalPages"
          :key="page"
          @click="goToPage(page)"
          :class="['btn-number', { active: page === currentPage }]"
        >
          {{ page }}
        </button>
        <button
          @click="nextPage"
          :class="['btn', { active: currentPage < totalPages }]"
          :disabled="currentPage === totalPages"
        >
          Следующая 🡢
        </button>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}
button.btn {
  color: #000;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px;
  background: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
button.btn.active {
  opacity: 1;
  visibility: visible;
}
.btn-number {
  color: #363636;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  border: 1px solid #d9d9d9;
  padding: 10px;
  background: none;
  background-color: transparent;
  cursor: pointer;
}
.btn-number.active {
  color: #000;
}
.products {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0px;
  margin-bottom: 35px;
}
.banner {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
}
.banner.hide {
  display: none;
  visibility: hidden;
}
.products-head__content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}
.products-head__title {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 50.4px */
  margin-bottom: 35px;
}

@media screen and (max-width: 1440px) {
  .products {
    grid-template-columns: repeat(4, 1fr);
  }
  .banner {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
  }
}
@media screen and (max-width: 1024px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .banner {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .mini-product {
    max-width: none;
  }
  
}
@media screen and (max-width: 650px) {
}
</style>