<script>
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "MiniProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    SliceProductName() {
      let brand = null;
      if (!this.product.attribute) {
        console.log("нет атрибута");
      } else {
        for (let a of this.product?.attribute) {
          console.log(a);
          if (
            a != null &&
            a.attrGroup != null &&
            a.attrGroup == "Производитель"
          ) {
            brand = a.attr;
          }
        }
      }
      if (this.product.name.length > 10) {
        const index = this.product.name.indexOf(" ", 15);

        if (index !== -1) {
          return {
            topName: this.product.name.substring(0, index),
            botName: this.product.name.substring(index + 1),
          };
        } else {
          return {
            topName: this.product.name,
            botName: brand,
          };
        }
      } else {
        return {
          topName: this.product.name,
          botName: brand,
        };
      }
    },
  },
  components: {},
  data() {
    return {
      Images: [],
      cartId: null,
      mainImage: null,
      price: null,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  methods: {
    productItem(product) {
      console.log("prod", product);
      this.mainImage =
        this.apiDomain + "/web/uploads/products/" + product.productPhoto[0];
    },

    addToWishList(product) {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}&id=${product}`;
      console.log(params);
      axios
        .post(this.apiUrl + "api/product/add-save-product-by-user-id" + params)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error("Error adding to wishlist:", error);
        });
    },
    addToCart() {
      let params;
      if (this.cartId === null) {
        params = `&auth=${this.user.username}:${this.user.auth_key}&product_id=${this.product.id}`;
      } else {
        params = `&auth=${this.user.username}:${this.user.auth_key}&product_id=${this.product.id}&cart_id=${this.cartId}`;
      }

      console.log(params);
      axios
        .get(this.apiUrl + "api/cart/add-to-cart" + params)
        .then((response) => {
          console.log("cartAdd", response);
          const cartId = response.data.cart_id;

          if (!Cookies.get("cartId")) {
            Cookies.set("cartId", cartId, { expires: 7 });
            this.cartId = cartId;
          }
        })
        .catch((error) => {
          console.error("Error adding to cart:", error);
        });
    },
  },
  mounted() {
    this.productItem(this.product);
    const cartIdFromCookie = Cookies.get("cartId");
    if (cartIdFromCookie) {
      this.cartId = cartIdFromCookie;
    }
  },
};
</script>

<template>
  <div class="mini-product">
    <a @click="addToWishList(product.id)" class="add-to-wihlist">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
      >
        <g clip-path="url(#clip0_1130_3945)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.102 23.9152C11.0086 23.9152 10.9398 23.8709 10.8661 23.8218L1.78867 14.7441C-2.01037 10.947 0.623896 4.34998 6.10376 4.34998C7.75509 4.34998 9.2639 4.98856 10.4188 6.11836L11.1266 6.82571L11.8343 6.11836C14.1933 3.76052 18.0612 3.76052 20.4448 6.11836C22.8284 8.49585 22.8284 12.3617 20.4448 14.7245L11.3674 23.7973C11.2937 23.8906 11.2003 23.9152 11.1069 23.9152H11.102ZM2.26048 6.61449C0.161917 8.73654 0.161917 12.1505 2.26048 14.2775L11.102 23.0948L19.9484 14.2578C22.0715 12.1358 22.0715 8.7169 19.9484 6.59484C17.8253 4.47278 14.4046 4.49734 12.2815 6.59484C10.9054 7.96533 11.3035 7.97025 9.92246 6.59484C7.78458 4.45313 4.40328 4.5219 2.26048 6.61449Z"
            fill="#D9D9D9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.102 20.5209C10.8022 20.5209 10.6401 20.1623 10.8661 19.956L18.248 12.5779C19.4275 11.399 19.4275 9.48812 18.248 8.33376C17.9187 8.00464 18.4347 7.54781 18.7198 7.86219C20.1598 9.30146 20.1598 11.6347 18.7198 13.0691L11.3379 20.4472C11.2888 20.4717 11.1954 20.5159 11.102 20.5159V20.5209ZM3.72511 13.1428C3.03706 13.1428 1.28743 10.0383 3.48921 7.83763C4.92921 6.39836 7.26368 6.39836 8.69876 7.83763C9.02805 8.17166 8.51201 8.62358 8.22695 8.3092C7.04743 7.13028 5.13562 7.13028 3.98068 8.3092C2.82573 9.48812 2.80115 11.399 3.98068 12.5533C4.23624 12.8038 3.98559 13.1428 3.7202 13.1428H3.72511Z"
            fill="#D9D9D9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.899 23.9152C17.8057 23.9152 17.7319 23.871 17.6631 23.8218L14.2671 20.4275C13.928 20.0886 14.4686 19.6612 14.7389 19.956L17.899 23.0948L26.7405 14.2578C28.8637 12.1358 28.8637 8.71691 26.7405 6.59485C24.8779 4.73314 21.8603 4.49735 19.7125 6.05451C19.3783 6.31486 18.9459 5.79908 19.3145 5.51417C21.7226 3.77035 25.1138 4.0307 27.2123 6.12819C29.596 8.50568 29.596 12.3716 27.2123 14.7343L18.1349 23.8071C18.0612 23.9004 17.9924 23.925 17.899 23.925V23.9152Z"
            fill="#D9D9D9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.899 20.5209C17.5992 20.5209 17.437 20.1623 17.6631 19.956L25.0449 12.5779C26.2097 11.4137 26.2097 9.49794 25.0449 8.33376C24.7107 8.00464 25.2317 7.54781 25.5167 7.86219C26.9567 9.43408 26.9174 11.6691 25.5167 13.0937L18.1349 20.4717C18.0612 20.4717 17.9727 20.5159 17.899 20.5159V20.5209Z"
            fill="#D9D9D9"
          />
        </g>
        <defs>
          <clipPath id="clip0_1130_3945">
            <rect width="29" height="29" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
    <router-link
      :to="{ name: 'ProductPage', params: { id: product.id } }"
      class="mini-product__img"
    >
      <img :src="mainImage" alt="" />
    </router-link>
    <div class="mini-product__info">
      <div class="mini-product__title">
        <p class="mini-product__name">{{ SliceProductName.topName }}</p>
        <p class="mini-product__index">{{ SliceProductName.botName }}</p>
      </div>
      <div class="mini-productAdd">
        <div class="mini-product__price-wrp">
          <div class="mini-product__price">{{ product.price }} ₽</div>
          <div v-if="product.oldPrice" class="mini-product__priceOld">
            {{ product?.oldPrice }} ₽
          </div>
        </div>
        <button class="btn__addToCart-mobile" @click="addToCart()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
          >
            <path
              d="M13.6646 25.9979H6.08002C4.01771 25.9979 2.30327 24.3642 2.17903 22.2833L1.35287 8.79754C1.32802 8.43105 1.46468 8.07077 1.71315 7.80366C1.96162 7.54277 2.29084 7.38126 2.66976 7.39989L13.6708 7.51792V8.38757L2.66355 8.26954C2.56416 8.25712 2.43371 8.31302 2.34675 8.4062C2.25978 8.49938 2.2163 8.6174 2.22251 8.74785L3.04247 22.2398C3.14185 23.8611 4.47738 25.1345 6.08002 25.1345H13.6646V26.0041V25.9979Z"
              fill="#1F1F1F"
            />
            <path
              d="M20.9262 25.9975H13.3416V25.1279H20.9262C22.5288 25.1279 23.8643 23.8545 23.9637 22.2332L24.7837 8.74123C24.7899 8.61078 24.7464 8.49275 24.6595 8.39958C24.5787 8.31261 24.4731 8.24428 24.3427 8.26292L13.3416 8.38094V7.5113L24.3302 7.39327C24.6905 7.38085 25.0384 7.53614 25.2868 7.79704C25.5353 8.06414 25.672 8.42442 25.6471 8.79092L24.8272 22.2829C24.7029 24.3638 22.9885 25.9975 20.9262 25.9975Z"
              fill="#1F1F1F"
            />
            <path
              d="M24.3985 21.2207H2.57654V22.0904H24.3985V21.2207Z"
              fill="#1F1F1F"
            />
            <path
              d="M9.1424 10.661C8.90015 10.661 8.70758 10.4685 8.70758 10.2262V6.69794C8.70758 2.80316 11.5526 1.34961 13.3416 1.34961C13.5838 1.34961 13.7764 1.54217 13.7764 1.78443C13.7764 2.02669 13.5838 2.21926 13.3416 2.21926C11.888 2.21926 9.57723 3.43676 9.57723 6.69794V10.2262C9.57723 10.4685 9.38466 10.661 9.1424 10.661Z"
              fill="#1F1F1F"
            />
            <path
              d="M17.6835 10.661C17.4413 10.661 17.2487 10.4685 17.2487 10.2262V6.69794C17.2487 3.43676 14.9379 2.21926 13.4844 2.21926C13.2421 2.21926 13.0496 2.02669 13.0496 1.78443C13.0496 1.54217 13.2421 1.34961 13.4844 1.34961C15.2734 1.34961 18.1184 2.80316 18.1184 6.69794V10.2262C18.1184 10.4685 17.9258 10.661 17.6835 10.661Z"
              fill="#1F1F1F"
            />
          </svg>
        </button>
      </div>
      <button class="btn btn__addToCart" @click="addToCart()">В корзину</button>
    </div>
  </div>
</template>

<style>
.btn__addToCart-mobile {
  display: none;
}
.btn__addToCart {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #1f1f1f;
  padding: 11px;
  width: 184px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  background: none;
}
.mini-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 392px;
  padding: 33px 15px 15px;
  border: 1px solid #d9d9d9;
  position: relative;
  overflow: hidden;
}
.mini-product:hover .btn__addToCart {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
  transform: translate(-50%, 0%);
}
.mini-product:hover .mini-product__price,
.mini-product:hover .mini-product__index {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.add-to-wihlist {
  position: absolute;
  top: 14.5px;
  right: 14.5px;
  z-index: 5;
  cursor: pointer;
}
.mini-product__img {
  max-height: 290px;
}
.mini-product__img img {
  height: 290px;
}
.mini-product__info {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 9px;
  width: 100%;
}
.mini-product__title {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.mini-product__name {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.mini-product__index {
  color: #a5a5a5;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.mini-product__price-wrp {
  display: flex;
  align-items: center;
  gap: 7px;
}
.mini-product__priceOld {
  color: #a5a5a5;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.mini-product__price {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
@media screen and (max-width: 1440px) {
  .mini-product {
    padding: 33px 19px 15px;
  }
}
@media screen and (max-width: 1024px) {
  .mini-productAdd {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
  .btn__addToCart{
    display: none;
  }
  .btn__addToCart-mobile {
    display: block;
    position: static;
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
    background: none;
    border: none;
  }
}
@media screen and (max-width: 650px) {
  .mini-product__img img {
    height: 154px;
  }
  .mini-product__name,
  .mini-product__index {
    font-size: 14px;
  }
  .mini-product__price {
    font-size: 16px;
  }
  .mini-product {
    padding: 13px;
  }
  .add-to-wihlist {
    top: 10px;
    right: 10px;
  }
}
</style>