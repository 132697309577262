<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import MainBtn from "../ui/main-btn.vue";
import MiniProductVue from "../product/MiniProduct.vue";
export default {
  name: "HomeSliderPertnrs",
  components: {
    Swiper,
    SwiperSlide,
    MiniProductVue,
    MainBtn,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getBlock() {
      let params = "&auth=" + this.user.username + ":" + this.user.auth_key;
      params += "&group_id=" + this.categoryId;
      console.log(
        "get-attribute",
        this.apiUrl + "api/compilation/get-attribute-by-group" + params
      );
      axios
        .get(this.apiUrl + "api/attribute/get-attribute-by-group" + params)
        .then((response) => {
          console.log("Привет", response.data);
          this.partners = response.data;
        });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    changeBackground(id) {
      let partner;
      let params = "&auth=" + this.user.username + ":" + this.user.auth_key;
      params += "&id=" + id;
      axios
        .get(this.apiUrl + "api/brand/get-brand-by-attr-id" + params)
        .then((response) => {
          partner = response.data;
          console.log("partners", partner);
          this.currentBackground =
            this.apiDomain + "/web/uploads/brands/" + partner.banner;
          this.description = partner.text;
          this.backgroundSquare =
            this.apiDomain + "/web/uploads/brands/" + partner.banner_square;
        });
      axios
        .get(this.apiUrl + "api/product/get-product-by-attribute" + params)
        .then((response) => {
          this.products = response.data.products;
          console.log("productsResponse", response.data);
        });
    },
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      partners: {},
      products: [],
      screenWidth: window.innerWidth,
      currentBackground: "",
      description: "",
      backgroundSquare: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  mounted() {
    this.getBlock();
    this.changeBackground(11);
  },
  computed: {
    isFirstSlide() {
      // Возвращает true, если слайдер на первом слайде
      return this.swiperActiveIndex === 0;
    },
    isLastSlide() {
      // Возвращает true, если слайдер на последнем слайде
      return this.swiperActiveIndex === this.partners.length - 1;
    },
    limitedProducts() {
      let limit = 4;
      if (this.screenWidth < 1441) {
        limit = 3;
      } else if (this.screenWidth < 900) {
        limit = 2;
      }
      return this.products.slice(0, limit);
    }
  },
  created() {
    window.addEventListener('resize', this.updateScreenWidth);
  }
};
</script>

<template>
  <div class="sliderPartners">
    <div class="container fl-col">
      <div class="sliderPartners__head">
        <h2 class="head-h2">Наши бренды</h2>
        <div class="arrows">
          <a class="prev" :class="{ 'grey-icon': isFirstSlide }">
            <!-- Добавлено применение класса grey-icon -->
            <svg
              width="56"
              height="21"
              viewBox="0 0 56 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.15147 11.2314H54.5669C55.2022 11.2314 55.7156 10.7181 55.7156 10.0827C55.7156 9.44731 55.2022 8.93397 54.5669 8.93397H3.92635L10.8976 1.96269C11.3463 1.51397 11.3463 0.785256 10.8976 0.336538C10.4489 -0.112179 9.72019 -0.112179 9.27147 0.336538L0.336601 9.27141C0.00634384 9.60167 -0.09058 10.0935 0.0889091 10.5242C0.268394 10.9514 0.688393 11.2314 1.15147 11.2314Z"
                fill="#D9D9D9"
              />
              <path
                d="M10.097 20.1769C10.3914 20.1769 10.6858 20.0656 10.9083 19.8395C11.3571 19.3908 11.3571 18.6621 10.9083 18.2133L1.96269 9.26769C1.51397 8.81897 0.785257 8.81897 0.336539 9.26769C-0.112179 9.71641 -0.112179 10.4451 0.336539 10.8938L9.28218 19.8395C9.50833 20.0656 9.80269 20.1769 10.097 20.1769Z"
                fill="#D9D9D9"
              />
            </svg>
          </a>
          <a class="next" :class="{ 'grey-icon': isLastSlide }">
            <!-- Добавлено применение класса grey-icon -->
            <svg
              width="56"
              height="21"
              viewBox="0 0 56 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M54.5641 11.2314H1.14872C0.513333 11.2314 0 10.7181 0 10.0827C0 9.44731 0.513333 8.93397 1.14872 8.93397H51.7892L44.818 1.96269C44.3692 1.51397 44.3692 0.785256 44.818 0.336538C45.2667 -0.112179 45.9954 -0.112179 46.4441 0.336538L55.379 9.27141C55.7092 9.60167 55.8062 10.0935 55.6267 10.5242C55.4472 10.9514 55.0272 11.2314 54.5641 11.2314Z"
                fill="#181818"
              />
              <path
                d="M45.6185 20.1769C45.3242 20.1769 45.0298 20.0656 44.8072 19.8395C44.3585 19.3908 44.3585 18.6621 44.8072 18.2133L53.7529 9.26769C54.2016 8.81897 54.9303 8.81897 55.379 9.26769C55.8278 9.71641 55.8278 10.4451 55.379 10.8938L46.4334 19.8395C46.2072 20.0656 45.9129 20.1769 45.6185 20.1769Z"
                fill="#181818"
              />
            </svg>
          </a>
        </div>
      </div>
      <swiper
        :slidesPerView="'auto'"
        :spaceBetween="20"
        :modules="modules"
        :navigation="{enabled: true, prevEl: '.prev', nextEl: '.next'}"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="item in partners"
          :key="item.id"
          @click="changeBackground(item.id)"
        >
          <div class="partner">{{ item.value }}</div>
        </swiper-slide>
      </swiper>
      <div
        class="sliderPartners__background"
        :style="{ backgroundImage: 'url(' + currentBackground + ')' }"
      ></div>
      <div class="sliderPartners__content">
        <div class="products__items">
          <MiniProductVue
            class="mini-prod"
            v-for="item in limitedProducts"
            :key="item.id"
            :product="item"
          ></MiniProductVue>
        </div>
        <div
          class="banner__text"
          :style="{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + backgroundSquare + ')' }"
        >
          <p class="banner__text-text">
            {{ description }}
          </p>
          <main-btn :msg="'Перейти в каталог'" :link="url"></main-btn>
        </div>
      </div>
    </div>
  </div>
</template>



<style scoped>
.banner__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 72px;
  align-items: center;
  grid-column: span 2;
}
.arrows{
  display: flex;
  gap: 16px;
  
}
.arrows a path{
  fill: #181818;
}
.arrows a.swiper-button-disabled path{
  fill: #D9D9D9;
}
.sliderPartners__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.products__items {
  display: grid;
  grid-column: span 4;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.sliderPartners {
  padding-top: 168px;
  padding-bottom: 168px;
}
.swiper-slide {
  width: max-content;
  cursor: grabbing;
}
.fl-col {
  display: flex;
  flex-direction: column;
  gap: 39px;
}
.swiper {
  width: 100%;
  height: 100%;
  background: #1f1f1f;
  padding: 28px 35px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
  font-family: Gotham;
  font-size: 30.15px;
  font-style: normal;
  font-weight: 900; /* 42.21px */
}
.sliderPartners__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sliderPartners__background {
  width: 100%;
  height: 406px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}
.grey-icon path {
  fill: grey; /* Применение серого цвета к иконкам */
}
.banner__text-text {
  color: #fff;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 29.078px */
}
@media screen and (max-width: 1440px) {
  .products__items {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .sliderPartners__background{
    height: 310px;
  }
}
@media screen and (max-width: 1024px) {
  .products__items {
    grid-template-columns: 1fr 1fr;
  }
  .sliderPartners__content{
    grid-template-columns: 1fr 1fr;
  }
  .sliderPartners__background{
    height: 164px;
  }
  .banner__text{
    height: 334px;
  }
  .banner__text-text{
    font-size: 20.77px;
  }
  .partner{
    font-size: 24.15px;
  }
  .swiper{
    padding: 20px;
  }
  .sliderPartners{
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 550px) {
  .partner{
    font-size: 18px;
  }
  .swiper{
    padding: 12px;
  }
  .sliderPartners{
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
</style>