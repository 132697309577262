<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import mainBtnVue from "../ui/main-btn.vue";

export default {
  name: "AboutPage",
  components: {
    HeaderApp,
    FooterApp,
    mainBtnVue,
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<template>
  <HeaderApp></HeaderApp>
  <div class="main-block">
    <div class="container h100">
      <div class="main-block__content">
        <div class="main-block__text black">
          Мы, Евгений и Анастасия - <br />
          владельцы салонов <br />
          Библиотека Часов
          <mainBtnVue
            style="margin-top: 30px"
            :link="'#modal'"
            :msg="'Купить'"
            :className="'black'"
          ></mainBtnVue>
        </div>
      </div>
    </div>
  </div>
  <div class="plan">
    <div class="container">
      <div class="plan__items">
        <div class="plan__item">
          <div class="plan__item-head">Заказать через сайт</div>
          <div class="plan__item-text">
            Нажмите кнопку «Купить»<br />
            и заполните форму заказа
          </div>
          <main-btn-vue
            :className="'green'"
            :msg="'Перейти в каталог'"
          ></main-btn-vue>
        </div>
        <div class="plan__item">
          <div class="plan__item-head">Покупка в магазине</div>
          <div class="plan__item-text">
            г. Севастополь ул. Генерала Острякова д. 260 ТЦ «Sea Mall» 1 этаж
            <br />
            г. Севастополь ул. Большая Морская д. 52
          </div>
          <main-btn-vue
            :className="'green'"
            :msg="'Перейти в контакты'"
          ></main-btn-vue>
        </div>
        <div class="plan__item">
          <div class="plan__item-head">Обратный звонок</div>
          <div class="plan__item-text">
            Закажите обратный звонок <br />
            и обсудите все детали с консультантом
          </div>
          <main-btn-vue
            class="mt8"
            :className="'green'"
            :msg="'Заказать звонок'"
          ></main-btn-vue>
        </div>
        <div class="plan__item">
          <div class="plan__item-head">Закажите по e-mail</div>
          <div class="plan__item-text">
            Напишите нам на электронную почту для обсуждения деталей заказа:
            <a href="mailto:example@example.com">example@example.com</a>
          </div>
          <main-btn-vue
            :className="'green'"
            :msg="'Заказать'"
          ></main-btn-vue>
        </div>
      </div>
    </div>
  </div>
  <div class="delivery-content">
    <div class="container">
      <div class="delivery__items">
        <div class="delivery__item">
          <div class="delivery__item-text">
            <div class="delivery__item-head">Самовывоз</div>
            <div class="delivery__item-description">
              Доступен из наших салонов по адресу:<br /><br />
              г. Севастополь ул. Генерала Острякова д. 260 ТЦ «Sea Mall» 1
              этаж<br /><br />
              г. Севастополь ул. Большая Морская д. 52<br /><br />
              Перемещение часов под заказ между бутиками занимает 1-2 дня.
            </div>
          </div>
          <div class="delivery__item-img">
            <img src="../../assets/img/delItem1.png" alt="" />
          </div>
        </div>
        <div class="delivery__item">
          <div class="delivery__item-img">
            <img src="../../assets/img/delItem2.png" alt="" />
          </div>
          <div class="delivery__item-text">
            <div class="delivery__item-head">Доставка по Крыму и России</div>
            <div class="delivery__item-description">
              Бесплатная доставка наручных часов по всей России. Осуществляется
              с помощью ТК СДЕК (до пункта выдачи) или Почтой России. (занимает
              от 2 до 10 рабочих дней) <br /><br />
              При получении в отделениях СДЕК возможна примерка и осмотр товара
              перед оплатой.<br /><br />
              Доставку по Севастополю и ближайшим районам мы осуществляем сами,
              что дает дополнительные возможности и преимущества, такие как:<br /><br />
              - Выбор на дому из нескольких моделей (Если у Вас нет возможности
              подъехать в наш салон, то мы привезем до 5 моделей на выбор. Их
              можно осмотреть, примерять и определиться прямо у Вас дома)<br /><br />
              *При покупке часов доставка бесплатная. Если Вам ничего не
              подошло, стоимость доставки 250 руб.<br /><br />
              - Сразу при покупке мы бесплатно укоротим браслет или сделаем
              замену ремешка.<br /><br />
              - Расскажем тонкости настройки механизма и особенности функций.<br /><br />
              Стоимость доставки аксессуаров, ремней и настенных часов
              уточняется у менеджера.
            </div>
          </div>
        </div>
        <div class="delivery__item">
          <div class="delivery__item-text">
            <div class="delivery__item-head">Оплата</div>
            <div class="delivery__item-description">
              Оплата на сайте осуществляется с помощью сервиса Яндекс Касса.
              Любой картой или Киви кошельком.<br /><br />
              Так же возможна услуга «Оплата по частям». Таким образом вы можете
              оформить покупку без первоначального взноса и процентов. Рассрочка
              доступна сроком от 3 до 18 месяцев.<br /><br />
              Или оплатите покупку наличными в магазине или при получении ТК.
            </div>
          </div>
          <div class="delivery__item-img">
            <img src="../../assets/img/delItem3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>

<style scoped>
.delivery-content{
  margin-bottom: 168px;
}
.delivery__items {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.delivery__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 37px;
}
.delivery__item-text {
  width: calc(50% - 18.5px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.delivery__item-description {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}
.delivery__item-head {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.delivery__item-img {
  width: calc(50% - 18.5px);
  flex-shrink: 0;
  height: 529px;
}
.delivery__item-img img{
  width: 100%;
  height: 100%;
}
.plan__items {
  display: flex;
  justify-content: space-between;
}
.plan__item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border: 1px solid #d9d9d9;
  padding: 40px 20px;
}
.plan__item .main-btn {
  margin-top: auto;
}
.plan__item-head {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.plan {
  margin-bottom: 70px;
}
.mt8 {
  margin-top: 8px;
}
.plan__item-text {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.main-block {
  background-image: url(../../assets/img/delivery.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  height: calc(100vh - 89px);
  margin-bottom: 81px;
}
.main-block__content {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 156px;
}
.main-block__text {
  color: #fff;
  font-family: "PT Sans Narrow";
  font-size: 58.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
}
.main-block__text.black {
  color: #1f1f1f;
}
@media screen and (max-width: 1440px) {
  .delivery__item{
    gap: 30px;
  }
}
@media screen and (max-width: 1028px) {
  .plan__items{
    flex-wrap: wrap;
  }
  .plan__item{
    width: 50%;
    padding: 40px 10px;
  }
  .delivery__item{
    flex-direction: column;
  }
  .delivery__item:nth-of-type(2){
    flex-direction: column-reverse;
  }
  .delivery__item-text, .delivery__item-img{
    width: 100%;
  }
  .main-block__content {
    padding-left: 41px;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 48.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .main-block {
    background-position: center;
  }
  .delivery__item-img{
    border-bottom: 2px solid #42B239;
  }
}
@media screen and (max-width: 550px) {
  .main-block__content {
    padding-left: 0px;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20%;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 32.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .main-block {
    background-position: center;
  }
  .plan__item{
    width: 100%;
    padding: 25px 10px;
  }
  .plan__item-head{
    font-size: 20px;
  }
  .plan__item-text, .delivery__item-description{
    font-size: 16px;
  }
  .delivery__item-head{
    font-size: 24px;
  }
  .delivery__item-img{
    height: 256px;
  }
}
</style>