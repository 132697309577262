<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import mainBtnVue from "../ui/main-btn.vue";
export default {
  name: "ServicesPage",
  components: {
    HeaderApp,
    FooterApp,
    mainBtnVue,
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<template>
  <HeaderApp></HeaderApp>

  <div class="main-block">
    <div class="container h100">
      <div class="main-block__content">
        <div class="main-block__text">
          Если бы часы <br />
          могли выбирать, <br />
          они выбрали бы нас
          <mainBtnVue
            style="margin-top: 30px"
            :link="'#modal'"
            :msg="'Купить'"
          ></mainBtnVue>
        </div>
      </div>
    </div>
  </div>
  <div class="services__content">
    <div class="container">
      <div class="service__list">
        <div class="products-head__content">
          <h1 class="sub-head-h2 black">Официальный сервисный центр</h1>
          <div class="services__text text">
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br /><br />
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br /><br />
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн.
          </div>
        </div>
        <div class="service__item">
          <div class="service__item-img">
            <img src="../../assets/img/service1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="services__items">
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item1.png" alt="" />
          </div>
          <div class="services__item-head">
            Проверка герметичности<br />
            (защита от влаги)
          </div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item2.png" alt="" />
          </div>
          <div class="services__item-head">
            Размагничивание<br />
            часов
          </div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item3.png" alt="" />
          </div>
          <div class="services__item-head">
            Проверка точности хода кварцевых<br />
            и механических часов
          </div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item4.png" alt="" />
          </div>
          <div class="services__item-head">
            Тест состояния <br />
            батарейки
          </div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item5.png" alt="" />
          </div>
          <div class="services__item-head">Замена и полировка стекла</div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item6.png" alt="" />
          </div>
          <div class="services__item-head">Ремонт и чистка для механики</div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item7.png" alt="" />
          </div>
          <div class="services__item-head">Полировка корпуса и браслета</div>
        </div>
        <div class="services__item">
          <div class="services__item-img">
            <img src="../../assets/img/ser-item8.png" alt="" />
          </div>
          <div class="services__item-head">
            Удлинение и замена ремешка (браслета)
          </div>
        </div>
      </div>
    </div>
    <div class="call_me">
      <div class="container h100">
        <div class="call_me__content">
          <div class="call_me__text">
            <h1 class="call_me__title">
              Доверьте ремонт ваших часов нашим высококлассным специалистам.
            </h1>
            <main-btn-vue :link="'/shop'" :msg="'Купить'"></main-btn-vue>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="service__list reverse">
        <div class="service__item">
          <div class="service__item-img">
            <img src="../../assets/img/service-dop.png" alt="" />
          </div>
        </div>
        <div class="products-head__content between">
          <h1 class="sub-head-h2 black">Какой-то простой заголовок</h1>
          <div class="services__text text">
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br /><br />
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br /><br />
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн.
          </div>
          <main-btn-vue
            class="green"
            :link="'/modal'"
            :className="'green'"
            :msg="'Связаться с нами'"
          ></main-btn-vue>
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>
.call_me {
  background-image: url(../../assets/img/remont.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 619px;
  margin-bottom: 168px;
}
.call_me__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.call_me__title {
  color: #fff;
  font-family: "PT Sans Narrow";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;

  max-width: 689px;
}
.call_me__text {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.h100 {
  height: 100%;
}
.services__items {
  display: flex;
  justify-content: space-between;
  gap: 35px;
  flex-wrap: wrap;
  margin-bottom: 168px;
}
.services__item {
  width: calc(25% - 28px);

  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  text-align: center;
}
.services__item-img {
  width: 100%;
  height: 376px;
  flex-shrink: 0;
  overflow: hidden;
}
.services__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
}
.services__item-img:hover img {
  transform: scale(1.1);
}
.services__item-head {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.service__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  padding: 81px 0;
}
.products-head__content {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.green {
  margin-top: auto;
}
.between {
  justify-content: space-between;
  height: 100%;
}
.service__item-img img {
  height: 569px;
}
@media screen and (max-width: 1200px) {
  .services__item {
    width: calc(33.33% - 20px);
  }
  .services__items {
    gap: 20px;
  }
}
@media screen and (max-width: 990px) {
  .service__list {
    flex-direction: column;
  }
  .products-head__content {
    max-width: none;
  }
}
@media screen and (max-width: 768px) {
  .services__item {
    width: calc(50% - 14px);
  }
}
.main-block {
  background-image: url(../../assets/img/service.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  height: calc(100vh - 89px);
}
.main-block__content {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 156px;
}
.main-block__text {
  color: #fff;
  font-family: "PT Sans Narrow";
  font-size: 58.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
}
@media screen and (max-width: 1440px) {
  .services__item-img {
    height: 284px;
  }
}
@media screen and (max-width: 1028px) {
  .main-block__content {
    padding-left: 41px;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 48.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .main-block {
    background-position: center;
  }
  .services__item-img {
    height: 313px;
  }
  .services__item-head {
    font-size: 20px;
  }
  .services__items {
    gap: 20px 17px;
  }
  .services__item {
    width: calc(50% - 9px);
  }
  .call_me__title{
    font-size: 38px;
  }
  .call_me{
    background-position: left 28% center;
    margin-bottom: 0;
  }
  .service__list.reverse{
    flex-direction: column-reverse;
  }
  .products-head__content{
    max-width: 100%;
  }
  .service__list .service__item{
    width: 100%;
  }
  .service__item-img img{
    height: 616px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media screen and (max-width: 550px) {
  .main-block__content {
    padding-left: 0px;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20%;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 32.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .main-block {
    background-position: center;
  }
  .text{
    font-size: 16px;
  }
  .service__list{
    padding: 50px 0;
  }
  .service__item-img img{
    height: 297px;
  }
  .services__item-img{
    height: 149px;
  }
  .services__item-head{
    font-size: 16px;
  }
  .services__item{
    gap: 10px;
  }
  .services__items{
    margin-bottom: 70px;
  }
  .call_me__title{
    font-size: 28px;
  }
  .call_me{
    height: 293px;
  }
}
</style>