<script>
import HeaderApp from "../blocks/header.vue";
import HomeMainBlock from "../blocks/HomeMainBlock.vue";
import HomeAdvantagesBlock from "../blocks/HomeAdvantagesBlock.vue";
import HomeCategoryBlock from "../blocks/HomeCategoryBlock.vue";
import HomeSliderPertnrs from "../blocks/HomeSliderPertnrs.vue";
import HomeAdvantages from "../blocks/HomeAdvantages.vue";
import HomeCitataBlock from "../blocks/HomeCitataBlock.vue";
import HomeAboutBlock from "../blocks/HomeAboutBlock.vue";
import footerVue from "../blocks/footer.vue";

export default {
  name: "HomeApp",
  components: {
    HeaderApp,
    HomeMainBlock,
    HomeAdvantagesBlock,
    HomeCategoryBlock,
    HomeSliderPertnrs,
    HomeAdvantages,
    HomeCitataBlock,
    HomeAboutBlock,
    footerVue
  },
};
</script>

<template>
  <HeaderApp></HeaderApp>
  <HomeMainBlock></HomeMainBlock>
  <HomeAdvantagesBlock></HomeAdvantagesBlock>
  <HomeCategoryBlock :compilationBlockById="2"></HomeCategoryBlock>
  <HomeCategoryBlock :compilationBlockById="16"></HomeCategoryBlock>

  <HomeSliderPertnrs :categoryId="1"></HomeSliderPertnrs>
  <HomeAdvantages></HomeAdvantages>
  <HomeCitataBlock></HomeCitataBlock>
  <HomeAboutBlock :page="'home'"></HomeAboutBlock>
  <footerVue></footerVue>
</template>

<style scoped>
</style>

