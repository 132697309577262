<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import mainBtnVue from "../ui/main-btn.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
export default {
  name: "AccountPage",
  components: {
    HeaderApp,
    FooterApp,
    BreadCrumbs,
    mainBtnVue,
    Swiper,
    SwiperSlide,
  },
  methods: {},
  mounted() {},
  setup() {
    const swiperActiveIndex = 0;
    const onSwiper = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      orders: [
        {
          id: 1,
          date: "12.12.2022",
          status: "Доставлено",
          price: "100000",
          products: [
            {
              id: "6",
              name: "Casio GA-100-2A",
              price: "15200",
              description: "",
              quantity: "0",
              category_id: "1",
              flag: ["0ea5ec0cb01c527e0e7c80e885a85cf7.png"],
              productPhoto: ["34169b1ad70fa6f6eec54103e34fae3f.png"],
              attribute: [
                {
                  attr: "Шарм",
                  attrGroup: "Производитель",
                },
                null,
                {
                  attr: "5 Бар",
                  attrGroup: "Водостойкость",
                },
              ],
              sale: [],
            },
            {
              id: "6",
              name: "Casio GA-100-2A",
              price: "15200",
              description: "",
              quantity: "0",
              category_id: "1",
              flag: ["0ea5ec0cb01c527e0e7c80e885a85cf7.png"],
              productPhoto: ["34169b1ad70fa6f6eec54103e34fae3f.png"],
              attribute: [
                {
                  attr: "Шарм",
                  attrGroup: "Производитель",
                },
                null,
                {
                  attr: "5 Бар",
                  attrGroup: "Водостойкость",
                },
              ],
              sale: [],
            },
          ],
        },
        {
          id: 2,
          date: "12.12.2022",
          status: "В обработке",
          price: "100000",
          products: [
            {
              id: "6",
              name: "Casio GA-100-2A",
              price: "15200",
              description: "",
              quantity: "0",
              category_id: "1",
              flag: ["0ea5ec0cb01c527e0e7c80e885a85cf7.png"],
              productPhoto: ["34169b1ad70fa6f6eec54103e34fae3f.png"],
              attribute: [
                {
                  attr: "Шарм",
                  attrGroup: "Производитель",
                },
                null,
                {
                  attr: "5 Бар",
                  attrGroup: "Водостойкость",
                },
              ],
              sale: [],
            },
          ],
        },
      ],
      ordersAccount: [],
    };
  },
};
</script>

<template>
  <HeaderApp></HeaderApp>
  <BreadCrumbs></BreadCrumbs>
  <div class="account-page">
    <div class="container">
      <div class="account-page__content mb35">
        <h1 class="sub-head-h2 black">Личный кабинет</h1>
      </div>

      <div class="account-page__content">
        <div class="form__items">
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">1. Личная информация</h3>
            </div>
            <div class="form__item-content">
              <label for="" class="input-label">
                Имя
                <input
                  type="text"
                  id="city"
                  placeholder="Введите имя"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Фамилия
                <input
                  type="text"
                  id="street"
                  placeholder="Введите фамилию"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Отчество
                <input
                  type="text"
                  placeholder="Введите отчество"
                  class="input"
                  id="house"
                />
              </label>
              <label for="" class="input-label">
                Дата рождения
                <input
                  type="text"
                  id="apartment"
                  placeholder="Введите дату рождения"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                E-mail
                <input
                  type="text"
                  id="apartment"
                  placeholder="Введите e-mail"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Телефон
                <input
                  type="text"
                  id="apartment"
                  placeholder="Введите телефон"
                  class="input"
                />
              </label>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">2. Изменение пароля</h3>
            </div>
            <div class="form__item-content">
              <label for="" class="input-label">
                Новый пароль
                <input
                  type="text"
                  id="city"
                  placeholder="Новый пароль"
                  class="input"
                />
              </label>
              <label for="" class="input-label">
                Подтверждение нового пароля
                <input
                  type="text"
                  id="street"
                  placeholder="Подтверждение нового пароля"
                  class="input"
                />
              </label>
              <mainBtnVue :className="'green'" :msg="'Обновить'"></mainBtnVue>
            </div>
          </div>
        </div>
        <div class="form__items">
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">3. Адрес доставки</h3>
              <a href="" class="accountLogin">Добавить</a>
              <div class="arrows">
                <a class="prev" :class="{ 'grey-icon': isFirstSlide }">
                  <svg
                    width="56"
                    height="21"
                    viewBox="0 0 56 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.15147 11.2314H54.5669C55.2022 11.2314 55.7156 10.7181 55.7156 10.0827C55.7156 9.44731 55.2022 8.93397 54.5669 8.93397H3.92635L10.8976 1.96269C11.3463 1.51397 11.3463 0.785256 10.8976 0.336538C10.4489 -0.112179 9.72019 -0.112179 9.27147 0.336538L0.336601 9.27141C0.00634384 9.60167 -0.09058 10.0935 0.0889091 10.5242C0.268394 10.9514 0.688393 11.2314 1.15147 11.2314Z"
                      fill="#D9D9D9"
                    />
                    <path
                      d="M10.097 20.1769C10.3914 20.1769 10.6858 20.0656 10.9083 19.8395C11.3571 19.3908 11.3571 18.6621 10.9083 18.2133L1.96269 9.26769C1.51397 8.81897 0.785257 8.81897 0.336539 9.26769C-0.112179 9.71641 -0.112179 10.4451 0.336539 10.8938L9.28218 19.8395C9.50833 20.0656 9.80269 20.1769 10.097 20.1769Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </a>
                <a class="next" :class="{ 'grey-icon': isLastSlide }">
                  <svg
                    width="56"
                    height="21"
                    viewBox="0 0 56 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M54.5641 11.2314H1.14872C0.513333 11.2314 0 10.7181 0 10.0827C0 9.44731 0.513333 8.93397 1.14872 8.93397H51.7892L44.818 1.96269C44.3692 1.51397 44.3692 0.785256 44.818 0.336538C45.2667 -0.112179 45.9954 -0.112179 46.4441 0.336538L55.379 9.27141C55.7092 9.60167 55.8062 10.0935 55.6267 10.5242C55.4472 10.9514 55.0272 11.2314 54.5641 11.2314Z"
                      fill="#181818"
                    />
                    <path
                      d="M45.6185 20.1769C45.3242 20.1769 45.0298 20.0656 44.8072 19.8395C44.3585 19.3908 44.3585 18.6621 44.8072 18.2133L53.7529 9.26769C54.2016 8.81897 54.9303 8.81897 55.379 9.26769C55.8278 9.71641 55.8278 10.4451 55.379 10.8938L46.4334 19.8395C46.2072 20.0656 45.9129 20.1769 45.6185 20.1769Z"
                      fill="#181818"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <Swiper
              :slides-per-view="1"
              :space-between="24"
              :modules="modules"
              :navigation="{ enabled: true, prevEl: '.prev', nextEl: '.next' }"
              @swiper="onSwiper"
              class="mySwiper"
            >
              <swiper-slide>
                <div class="form__item-content">
                  <label for="" class="input-label">
                    Город
                    <input
                      type="text"
                      id="city"
                      placeholder="Введите город"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Улица
                    <input
                      type="text"
                      id="street"
                      placeholder="Введите улицу"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Квартира
                    <input
                      type="text"
                      placeholder="Введите квартиру"
                      class="input"
                      id="house"
                    />
                  </label>
                  <label for="" class="input-label">
                    Дом
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите дом"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Подъезд
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите подъезд"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Этаж
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите этаж"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label input-textarea">
                    Комментарий
                    <textarea
                      name=""
                      class="input input-textarea"
                      placeholder="Напишите комментарий"
                      id=""
                    ></textarea>
                  </label>
                  <mainBtnVue
                    :className="'green'"
                    :msg="'Обновить'"
                  ></mainBtnVue>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="form__item-content">
                  <label for="" class="input-label">
                    Город
                    <input
                      type="text"
                      id="city"
                      placeholder="Введите город"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Улица
                    <input
                      type="text"
                      id="street"
                      placeholder="Введите улицу"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Квартира
                    <input
                      type="text"
                      placeholder="Введите квартиру"
                      class="input"
                      id="house"
                    />
                  </label>
                  <label for="" class="input-label">
                    Дом
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите дом"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Подъезд
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите подъезд"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label">
                    Этаж
                    <input
                      type="text"
                      id="apartment"
                      placeholder="Введите этаж"
                      class="input"
                    />
                  </label>
                  <label for="" class="input-label input-textarea">
                    Комментарий
                    <textarea
                      name=""
                      class="input input-textarea"
                      placeholder="Напишите комментарий"
                      id=""
                    ></textarea>
                  </label>
                  <mainBtnVue
                    :className="'green'"
                    :msg="'Обновить'"
                  ></mainBtnVue>
                </div>
              </swiper-slide>
            </Swiper>
          </div>
        </div>
        <div class="form__items bonus">
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="256"
              height="239"
              viewBox="0 0 256 239"
              fill="none"
            >
              <path
                d="M192.016 0C156.577 0 128.032 28.6107 128.032 63.9838C128.032 99.357 156.643 127.968 192.016 127.968C227.389 127.968 256 99.357 256 63.9838C256 28.6107 227.454 0 192.016 0ZM192.016 119.515C161.324 119.515 136.55 94.8053 136.55 64.0489C136.55 33.2924 161.324 8.51817 192.016 8.51817C222.707 8.51817 247.481 33.2924 247.481 63.9838C247.481 94.6753 222.707 119.449 192.016 119.449V119.515Z"
                fill="#1F1F1F"
              />
              <path
                class="checkPath"
                d="M218.937 39.6664L179.272 79.3312L165.162 65.2209C163.471 63.5303 160.87 63.5303 159.18 65.2209C157.489 66.9116 157.424 69.5125 159.18 71.2032L176.281 88.3045C177.126 89.1498 178.427 89.54 179.272 89.54C180.118 89.54 181.418 89.54 182.263 88.3045L224.919 45.6486C226.675 43.893 226.675 41.357 224.919 39.6664C223.164 37.9758 220.628 37.9758 218.937 39.6664Z"
                fill="#1F1F1F"
              />
              <path
                d="M226.154 128.486C223.553 128.486 221.863 130.176 221.863 132.777V140.84H8.51817V115.221H131.414C133.95 115.221 135.706 113.53 135.706 110.929C135.706 108.328 133.95 106.638 131.414 106.638H8.51817V98.1195C8.51817 90.9018 14.0452 85.3098 21.3279 85.3098H117.369C119.97 85.3098 121.66 83.6191 121.66 81.0182C121.66 78.4172 119.97 76.7266 117.369 76.7266H21.3279C9.36349 76.7266 0 86.1551 0 98.0545V217.569C0 229.533 9.36349 238.897 21.3279 238.897H209.118C221.082 238.897 230.446 229.533 230.446 217.569V132.647C230.446 130.046 228.755 128.356 226.154 128.356V128.486ZM209.053 230.444H21.3279C14.1103 230.444 8.51817 224.917 8.51817 217.634V149.359H221.928V217.634C221.928 224.917 216.401 230.444 209.118 230.444H209.053Z"
                fill="#1F1F1F"
              />
              <path
                d="M76.7929 200.535H34.1371C31.5361 200.535 29.8455 202.291 29.8455 204.827C29.8455 207.363 31.5361 209.118 34.1371 209.118H76.7929C79.3939 209.118 81.0845 207.428 81.0845 204.827C81.0845 202.226 79.3939 200.535 76.7929 200.535Z"
                fill="#1F1F1F"
              />
              <path
                d="M189.026 219.781C177.062 219.781 167.698 210.417 167.698 198.453C167.698 186.488 177.062 177.125 189.026 177.125C200.991 177.125 210.354 186.554 210.354 198.453C210.354 210.352 200.991 219.781 189.026 219.781ZM189.026 185.643C181.809 185.643 176.216 191.17 176.216 198.453C176.216 205.736 181.743 211.263 189.026 211.263C196.309 211.263 201.836 205.736 201.836 198.453C201.836 191.17 196.309 185.643 189.026 185.643Z"
                fill="#1F1F1F"
              />
              <path
                d="M221.408 27.3113L224.074 24.5152C210.549 12.9409 195.853 12.5508 195.138 12.5508V16.3872C195.138 16.3872 208.988 16.7774 221.408 27.3113Z"
                fill="#1F1F1F"
              />
              <path
                d="M224.464 30.1085C225.504 31.1489 226.48 32.1893 227.455 33.3598L230.446 30.9539C229.341 29.6534 228.235 28.4179 227.13 27.3125L224.464 30.1085Z"
                fill="#1F1F1F"
              />
            </svg>
          </div>
          <div class="bonus">
            <div class="bonus__title">Бонусные баллы:</div>
            <div class="bonus__count">15 345 ₽</div>
          </div>
        </div>
      </div>
      <div class="account-page__content">
        <div class="form__items orders">
          <div class="form__item">
            <div class="form__item-head">
              <h3 class="sub-head-h3 black">4. История покупок</h3>
            </div>
            <div class="form__item-content">
              <div class="form__item-content-head">
                <div class="form__item-content-head--title">№ заказа</div>
                <div class="form__item-content-head--title">Дата</div>
                <div class="form__item-content-head--title">Состав заказа</div>
                <div class="form__item-content-head--title">Сумма</div>
                <div class="form__item-content-head--title text-end">
                  Статус
                </div>
              </div>
              <div class="form__item-content-body">
                <div
                  class="order__item"
                  v-for="order in orders"
                  :key="order.id"
                >
                  <div class="order__item--number">№ {{ order.id }}</div>
                  <div class="order__item--date text-center">
                    {{ order.date }}
                  </div>

                  <div class="order__item--composition text-center">
                    <img
                      v-for="product in order?.products"
                      :key="product.id"
                      :src="
                        apiDomain +
                        '/web/uploads/products/' +
                        product.productPhoto[0]
                      "
                    />
                  </div>
                  <div class="order__item--sum text-center">
                    {{ order.price }} ₽
                  </div>
                  <div class="order__item--status" text-end>
                    {{ order.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item-head space">
              <h3 class="sub-head-h3 black">5. Бонусные баллы</h3>
              <a><span class="bonus__count">15 345 ₽</span></a>
            </div>
            <div class="form__item-content">
              <div class="form__item-content-head bonus-head">
                <div class="form__item-content-head--title">
                  Начисление баллов
                </div>
                <div class="form__item-content-head--title">Дата</div>
              </div>
              <div class="form__item-content-body bonus-head">
                <div
                  class="order__item"
                  v-for="order in orders"
                  :key="order.id"
                >
                  <div class="order__item--number">+1000 баллов</div>
                  <div class="order__item--date text-center">13.05.2024</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>
.form__items {
  max-width: calc(33% - 20px);
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 15px;
}
.swiper {
  max-width: 100%;
}
.account-page__content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px;
}
.form__item-head {
  justify-content: space-between;
}
.arrows {
  margin-left: auto;
}
.form__items.bonus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.bonus {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.bonus__title {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.bonus__count {
  color: #397e33;
  font-family: "PT Sans Narrow";
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.form__items.bonus .checkPath {
  fill: transparent;
  transition: fill 0.3s ease-in-out;
}

.form__items.bonus:hover .checkPath {
  animation: flicker 3s alternate;
  fill: #397e33;
}

@keyframes flicker {
  20%,
  40% {
    fill: transparent;
  }
  0%,
  10%,
  30%,
  50%,
  100% {
    fill: #397e33;
  }
}
.form__item-content-head {
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 1fr 0.8fr 0.4fr;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.form__items.orders {
  width: 100%;
  max-width: none;
  display: flex;
  gap: 49px;
  flex-direction: row;
}
.orders .form__item-content {
  width: 100%;
  gap: 10px;
}
.orders .form__item {
  width: 100%;
  max-width: calc(50% - 20px);
}
.form__item-content-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  width: 100%;
}
.order__item {
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 1fr 0.8fr 0.4fr;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
}
.order__item:first-of-type {
  border-top: 1px solid #d9d9d9;
}
.order__item--composition {
  display: flex;
  width: 100%;
  gap: 3px;
}
.order__item--composition img {
  width: 54px;
  height: 54px;
}
.form__item-content-head--title:not(:first-of-type) {
  text-align: center;
}
.text-center {
  text-align: center;
  align-self: center;
}
.text-end {
  text-align: right;
  align-self: flex-end;
}
.form__item-content-head.bonus-head {
  display: flex;
  justify-content: space-between;
}
.bonus-head .order__item {
  display: flex;
  justify-content: space-between;
}
.account-page {
  margin-bottom: 168px;
}
.form__item-head .bonus__count {
  display: none;
  font-size: 30px;
}
@media screen and (max-width: 1440px) {
  .form__items {
    max-width: calc(50% - 20px);
  }
  .form__items.bonus {
    display: none;
  }
  .form__item-head .bonus__count {
    display: block;
  }
  .form__items.orders {
    gap: 30px;
  }

  .order__item {
    display: grid;
    grid-template-columns: 0.3fr 0.6fr 1fr 0.6fr 0.5fr;
  }
  .form__item-content-head {
    grid-template-columns: 0.3fr 0.6fr 1fr 0.6fr 0.5fr;
  }
}
@media screen and (max-width: 1024px) {
  .form__items {
    max-width: none;
  }
  .account-page__content {
    flex-direction: column;
  }
  .form__item-head.space {
    flex-direction: row;
    justify-content: space-between;
  }
  .form__items.orders {
    flex-direction: column;
  }
  .orders .form__item {
    max-width: none;
  }
}
@media screen and (max-width: 650px) {
  .text-center {
    text-align: left;
  }
  .form__item-content-head {
    display: none;
  }
  .order__item {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .order__item--composition {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .order__item--date {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 5;
    grid-column-end: 7;
    text-align: right;
  }
  .order__item--status{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 7;
    text-align: right;
  }
  .order__item--sum{
    white-space: nowrap;
  }
  .form__item-content-body {
    font-size: 16px;
  }
  .form__item-head .bonus__count{
    font-size: 26px;
  }
  .account-page__content{
    margin-bottom: 20px;
  }
}
</style>