<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<template>
  <router-view></router-view> 
</template>

<style>

</style>
