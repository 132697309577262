<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import axios from "axios";
export default {
  name: "ComparePage",
  components: {
    HeaderApp,
    FooterApp,
    BreadCrumbs,
  },
  data() {
    return {
      products: [],
      product: [],
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  methods: {
    getProducts() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}`;
      axios
        .post(
          this.apiUrl + "api/product/get-compare-product-by-user-id" + params
        )
        .then((res) => {
          this.products = res.data;
          console.log("compare", this.products);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addToCart(id) {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}&product_id=${id}`;
      axios
        .post(this.apiUrl + "api/cart/add-to-cart" + params)
        .then((response) => {
          console.log("fetchProduct", response);
        });
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<template>
  <HeaderApp></HeaderApp>

  <BreadCrumbs></BreadCrumbs>
  <div class="compare-page">
    <div class="container">
      <div class="compare-page__content">
        <div class="sub-head-h2 black">Сравнение товаров</div>

        <div class="compare-page__products">
          <div
            class="compare-page__product"
            v-for="product in products"
            :key="product"
          >
            <a href="" class="deleteCompareProduct">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
                  fill="#181818"
                />
              </svg>
            </a>
            <div class="compare-page__product-img">
              <img
                :src="
                  apiDomain +
                  '/web/uploads/products/' +
                  product.product.productPhoto[0].pic
                "
                :alt="product.name"
              />
            </div>
            <div class="compare-page__product-content">
              <div class="compare-page__product-info">
                <div class="compare-page__product-head">
                  <div class="compare-page__product-name">
                    {{ product.product.name }}
                  </div>
                  <div class="compare-page__product-price">
                    {{ product.product.price }} ₽
                  </div>
                </div>
                <a @click="addToCart(product.product.id)" class="add-to-cart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.1219 19.2579H4.50374C2.9761 19.2579 1.70614 18.0477 1.61411 16.5063L1.00214 6.51684C0.983736 6.24537 1.08496 5.97849 1.26902 5.78063C1.45307 5.58738 1.69694 5.46774 1.97762 5.48155L10.1265 5.56897V6.21316L1.97302 6.12573C1.8994 6.11653 1.80277 6.15794 1.73835 6.22696C1.67393 6.29598 1.64172 6.3834 1.64632 6.48003L2.2537 16.4741C2.32732 17.675 3.3166 18.6183 4.50374 18.6183H10.1219V19.2625V19.2579Z"
                      fill="#1F1F1F"
                    />
                    <path
                      d="M15.5008 19.258H9.88257V18.6138H15.5008C16.6879 18.6138 17.6772 17.6705 17.7508 16.4696L18.3582 6.47556C18.3628 6.37893 18.3306 6.29151 18.2662 6.22249C18.2063 6.15807 18.1281 6.10746 18.0315 6.12126L9.88257 6.20868V5.5645L18.0223 5.47708C18.2892 5.46787 18.5468 5.58291 18.7309 5.77616C18.9149 5.97402 19.0162 6.24089 18.9978 6.51237L18.3904 16.5064C18.2984 18.0479 17.0284 19.258 15.5008 19.258Z"
                      fill="#1F1F1F"
                    />
                    <path
                      d="M18.0731 15.7188H1.90869V16.3629H18.0731V15.7188Z"
                      fill="#1F1F1F"
                    />
                    <path
                      d="M6.77204 7.89736C6.59259 7.89736 6.44995 7.75472 6.44995 7.57527V4.96173C6.44995 2.07671 8.55735 1 9.88253 1C10.062 1 10.2046 1.14264 10.2046 1.32209C10.2046 1.50154 10.062 1.64418 9.88253 1.64418C8.80582 1.64418 7.09413 2.54604 7.09413 4.96173V7.57527C7.09413 7.75472 6.95149 7.89736 6.77204 7.89736Z"
                      fill="#1F1F1F"
                    />
                    <path
                      d="M13.0988 7.89736C12.9194 7.89736 12.7767 7.75472 12.7767 7.57527V4.96173C12.7767 2.54604 11.0651 1.64418 9.98835 1.64418C9.8089 1.64418 9.66626 1.50154 9.66626 1.32209C9.66626 1.14264 9.8089 1 9.98835 1C11.3135 1 13.4209 2.07671 13.4209 4.96173V7.57527C13.4209 7.75472 13.2783 7.89736 13.0988 7.89736Z"
                      fill="#1F1F1F"
                    />
                  </svg>
                </a>
              </div>
              <div class="product__attributes">
                <div
                  class="product__attribute"
                  v-for="attribute in product.attributes"
                  :key="attribute.id"
                >
                  <div class="product__attribute-title">
                    {{ attribute.title }}
                  </div>
                  <div class="product__attribute-value">
                    {{ attribute.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>
.compare-page__content {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.compare-page__products {
  display: flex;
  margin-bottom: 168px;
}
.compare-page__product {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #d9d9d9;
  width: calc(33% - 10px);
  position: relative;
}
.deleteCompareProduct{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.compare-page__product-img {
  width: 100%;
  height: 586px;
  padding: 15px 15px 0;
}
.compare-page__product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.compare-page__product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 15px 9px;
}
.compare-page__product-head {
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.add-to-cart {
  border: 1px solid #397e33;
  padding: 16px 17px;
  cursor: pointer;
}
.product__attributes {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.product__attribute {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px;
  width: 100%;
  font-family: "PT Sans Narrow";
  font-size: 20.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.product__attribute:first-of-type {
  border-top: 1px solid #d9d9d9;
}
.product__attribute-title {
  color: #a5a5a5;
}
.product__attribute-value {
  color: #1f1f1f;
}
</style>