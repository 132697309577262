<script>
import axios from "axios";
import MiniProductVue from "../product/MiniProduct.vue";
import MainBtn from "../ui/main-btn.vue";

export default {
  name: "HomeCategoryBlock",
  components: {
    MiniProductVue,
    MainBtn,
  },
  props: {
    compilationBlockById: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getBlock() {
      let params = "&auth=" + this.user.username + ":" + this.user.auth_key;
      params += "&id=" + this.compilationBlockById;
      console.log(this.apiUrl + "api/compilation/get-compilation-block-by-id" + params);
      axios
        .get(this.apiUrl + "api/compilation/get-compilation-block-by-id" + params)
        .then((response) => {
          console.log('response',response);
          this.compilationBlock = response.data;
          console.log(this.compilationBlock);
        });
    },
  },
  data(){
    return {
      compilationBlock: {},
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain
    }
  },
  mounted() {
    this.getBlock();
    
  }
};
</script>

<template>
  <div class="home-category-block">
    <div class="container">
      <div class="" :class="compilationBlock.position == 1 ? 'home-category__content right' : 'home-category__content' ">
        <div class="home-category__title" :style="{backgroundImage: 'url(' + apiDomain + '/web/uploads' + compilationBlock.pic + ')'}">
          <h2 class="title-category">{{ compilationBlock.title }}</h2>
          <main-btn :msg="compilationBlock.text_btn"></main-btn>
        </div>
        <div class="home-category__products" >
          <MiniProductVue class="mini-prod" v-for="item in compilationBlock.products" :key="item.id" :product="item"></MiniProductVue>
        </div>
      </div>
     
    </div>
  </div>
</template>

<style scoped>
.home-category__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  padding: 35px;
  /* background-image: url("../../assets/img/home-category-men.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-basis: 55%;
  flex-shrink: 0;
  border-right: 2px solid #42B239;
}
.right .home-category__title {
  /* background-image: url("../../assets/img/home-category-women.png"); */
  align-items: end;
  border-left: 2px solid #42B239;
  border-right: none;
}
.home-category__content {
  display: flex;
  width: 100%;
}

.home-category__content.right {
  justify-content: end;
  padding-top: 168px;
  flex-direction: row-reverse;
}
.home-category__products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
}

.title-category {
  color: #fff;
  font-family: "PT Sans Narrow";
  font-size: 28.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 40.278px */
}
.mini-prod{
  max-width: none;
}
@media screen and (max-width: 1440px) {
  .home-category__title{
    flex-basis: 52%;
  }
}
@media screen and (max-width: 1024px) {
  .home-category__title{
    flex-basis: 100%;
    min-height: 574px;
    height: 574px;
    flex-shrink: 0;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #42B239;
  }
  .right .home-category__title {
    border-left: none;
  }
  .home-category__content{
    flex-direction: column;
  }
  .home-category__content.right{
    flex-direction: column;
    padding-top: 70px;
  }
  .home-category__products{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .mini-prod{
    max-width: 50%;
    width: 50%;
  }
  
}
@media screen and (max-width: 550px) {
  .home-category__title{
    flex-basis: 100%;
    min-height: 278px;
    height: 278px;
    flex-shrink: 0;
    gap: 10px;
  }
  .title-category{
    font-size: 24px;
  }
}
</style>